export default [
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('@/views/my-account/MyAccount.vue'),
    meta: {
      resource: 'agent',
      action: 'update',
      pageTitle: 'My Account',
      breadcrumb: [
        {
          text: 'My Account',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/my-account/Profile.vue'),
    meta: {
      resource: 'agent',
      action: 'update',
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('@/views/my-account/Password.vue'),
    meta: {
      pageTitle: 'Update Password',
      breadcrumb: [
        {
          text: 'Update Password',
          active: true,
        },
      ],
    },
  },
]
