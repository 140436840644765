export default [
  {
    path: '/win-lose',
    name: 'win-lose',
    component: () => import('@/views/report/WinLose.vue'),
    meta: {
      resource: 'report',
      action: 'read',
      pageTitle: 'Win / Lose',
      breadcrumb: [
        {
          text: 'Win / Lose',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () => import('@/views/report/Transaction.vue'),
    meta: {
      resource: 'report',
      action: 'read',
      pageTitle: 'Transaction',
      breadcrumb: [
        {
          text: 'Transaction',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto-report',
    name: 'lotto-report',
    component: () => import('@/views/report/LottoReport.vue'),
    meta: {
      resource: 'report',
      action: 'read',
      pageTitle: 'Lotto Report',
      breadcrumb: [
        {
          text: 'Lotto Report',
          active: true,
        },
      ],
    },
  },
]
