// eslint-disable-next-line no-unused-vars
// const host = 'http://localhost:5002'
const host = 'https://api-huaydragon.com'
export default {
  // Endpoints
  // loginEndpoint: 'http://localhost:5000/auth/login',
  // loginEndpoint: '/jwt/login',
  // registerEndpoint: '/jwt/register',
  // refreshEndpoint: '/jwt/refresh-token',
  // logoutEndpoint: '/jwt/logout',
  loginEndpoint: `${host}/auth/login`,
  registerEndpoint: `${host}/auth/register`,
  refreshEndpoint: `${host}/auth/refresh-token`,
  logoutEndpoint: `${host}/auth/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
