import ability from './ability'

export const canNavigate = to => to.matched.some(route => {
  for (let i = 0; i < route.meta?.action?.length; i += 1) {
    if (ability.can(route.meta.action[i], route.meta.resource)) {
      return true
    }
  }
  return ability.can(route.meta.action || 'read', route.meta.resource)
})

export const _ = undefined
