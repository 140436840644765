import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    serverTime: null,
    windowWidth: 0,
    shallShowOverlay: false,
    userData: '',
    toNumber: v => (`${v}`).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
    DateFormat: (d, startTime) => {
      const pad = n => (n < 10 ? '0' : '') + n
      if (startTime) {
        return `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${d.getFullYear()} ${startTime}`
      }
      return `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${d.getFullYear()} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`
    },
    isMaster: store => store.state.app.userData.agent === 'master' || store.state.app.userData.username === 'master',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {
    LOAD_USER_DATA({
      commit,
      state,
    }) {
      commit('UPDATE_USER_DATA', JSON.parse(localStorage.getItem('userData')))
      return state.userData
    },
  },
}
