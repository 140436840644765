export default [
  {
    path: '/invoice/list',
    name: 'invoice-list',
    component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      // action: ['read', 'update'],
      // resource: 'invoice',
      resource: 'all',
      action: 'manage',
      pageTitle: 'Invoice List',
      breadcrumb: [
        {
          text: 'Invoice List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoice/preview/:id',
    name: 'invoice-preview',
    component: () => import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      // action: ['read', 'update'],
      // resource: 'invoice',
      resource: 'all',
      action: 'manage',
      pageTitle: 'Invoice Preview',
      breadcrumb: [
        {
          text: 'Invoice List',
          to: '/invoice/list',
        },
        {
          text: 'Invoice Preview',
          active: true,
        },
      ],
    },
  },
]
