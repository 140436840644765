export default [
  {
    path: '/add-agent',
    name: 'add-agent',
    component: () => import('@/views/account-members/AddAgent.vue'),
    meta: {
      resource: 'agent',
      action: 'update',
      pageTitle: 'Add Agent',
      breadcrumb: [
        {
          text: 'Add Agent',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agents',
    name: 'agents',
    component: () => import('@/views/account-members/Agents.vue'),
    meta: {
      resource: 'agent',
      action: ['read', 'update'],
      pageTitle: 'Agents',
      breadcrumb: [
        {
          text: 'Agents',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agents/edit',
    name: 'agents-edit',
    component: () => import('@/views/account-members/EditAgent.vue'),
    meta: {
      resource: 'agent',
      action: 'update',
      pageTitle: 'Edit Agent',
      breadcrumb: [
        {
          text: 'Edit Agent',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-sub-account',
    name: 'add-sub-account',
    component: () => import('@/views/account-members/AddSubAccount.vue'),
    meta: {
      resource: 'member',
      action: 'update',
      pageTitle: 'Add SubAccount',
      breadcrumb: [
        {
          text: 'Add SubAccount',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sub-account',
    name: 'sub-account',
    component: () => import('@/views/account-members/SubAccounts.vue'),
    meta: {
      resource: 'member',
      action: ['read', 'update'],
      pageTitle: 'Sub Accounts',
      breadcrumb: [
        {
          text: 'Sub Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sub-account/edit',
    name: 'sub-account-edit',
    component: () => import('@/views/account-members/EditSubAccount.vue'),
    meta: {
      resource: 'member',
      action: ['read', 'update'],

      pageTitle: 'Edit SubAccount',
      breadcrumb: [
        {
          text: 'Edit SubAccount',
          active: true,
        },
      ],
    },
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('@/views/account-members/Members.vue'),
    meta: {
      resource: 'member',
      action: ['read', 'update'],
      pageTitle: 'Members',
      breadcrumb: [
        {
          text: 'Members',
          active: true,
        },
      ],
    },
  },
  {
    path: '/members/edit',
    name: 'members-edit',
    component: () => import('@/views/account-members/EditMembers.vue'),
    meta: {
      resource: 'member',
      action: ['read', 'update'],
      pageTitle: 'Edit Member',
      breadcrumb: [
        {
          text: 'Edit Member',
          active: true,
        },
      ],
    },
  },
]
